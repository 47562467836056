<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <a href="/">
          <img alt="Logo" :src="headerLogo" class="minimized-logo" />
        </a>

        <div
          v-if="headerMenuEnabled"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
          <!-- example static menu here -->
          <KTMenu></KTMenu>
        </div>
      </div>
      <!-- end:: Header Menu -->
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";
import Application from "../../../application";

export default {
  name: "KTHeader",
  components: {
    KTTopbar,
    KTMenu,
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerLogo() {
      let logo;
      let ls = localStorage.getItem("teamLogo");
      // console.log(ls + "<---");
      if (this.$store.getters.teamLogo !== null) {
        logo = this.$store.getters.teamLogo;
      } else if (ls !== null && ls !== "null") {
        logo = ls;
        //console.log("LS: " + localStorage.getItem("teamLogo"));
      } else {
        logo = Application.constants.DEFAULT_LOGO;
        console.log("DEFAULT: " + logo);
      }
      return logo;
    },
  },
};
</script>
<style>
.minimized-logo {
  image-rendering: inherit;
  height: 50px;
  margin-top: 3px;
  margin-right: 10px;
}
.header-menu-wrapper {
  align-items: center;
}
</style>
