var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.items),function(item){return [(item.submenu == null || item.submenu.length === 0)?[_c('router-link',{key:item.key,attrs:{"to":'/' + item.page},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU." + item.code.toUpperCase()))+" ")])])])]}}],null,true)})]:[_c('li',{key:item.key,staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(item.page) },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU." + item.code.toUpperCase()))+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},_vm._l((item.submenu),function(menuItem){return _c('router-link',{key:menuItem.key,attrs:{"to":'/' + menuItem.page},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:'menu-icon ' + menuItem.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("MENU." + menuItem.code.toUpperCase()))+" ")])])])]}}],null,true)})}),1)])])]]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }