<template>
  <b-form @submit="onSubmit">
    <b-modal v-model="show_" ref="modal" :title="$t('FEEDBACK.SEND_FEEDBACK')">
      <b-form-group
        :label="$t('COMMON.DESCRIPTION')"
        label-for="input-1"
        :description="$t('FEEDBACK.DESCRIPTION_PROBLEM')"
      >
        <b-form-textarea
          id="input-1"
          v-model="form.text"
          type="email"
          autofocus
          required
          placeholder=""
        ></b-form-textarea>
      </b-form-group>

      <b-form-group id="input-group-2" :label="$t('FEEDBACK.SCREENSHOT')">
        <div
          v-if="!url"
          class="p-3 bg-secondary progress-bar-striped text-lg-center"
          style="line-height: 200px; cursor: pointer"
          @click="makeScreenshot"
        >
          {{ $t("FEEDBACK.MAKE_SCREENSHOT") }}
        </div>

        <section style="position: relative" v-else>
          <img class="screenshot-image" :src="url" alt="" />
          <b-button-close
            class="screenshot-image--btn-close"
            @click="form.image = null"
          />
        </section>
      </b-form-group>

      <template v-slot:modal-footer>
        <b-button @click="onClose" type="reset">{{
          $t("COMMON.CLOSE")
        }}</b-button>
        <b-button @click="onSubmit" type="submit" variant="primary"
          >{{ $t("FEEDBACK.SUBMIT") }}
        </b-button>
      </template>

      <debug>{{ form }}</debug>
    </b-modal>

    <Screenshot v-if="showScreenshot" @screenshot="onScreenshot" />
  </b-form>
</template>

<script>
import Screenshot from "@/view/layout/extras/Screenshot";
import ApiService from "@/core/services/api.service";
import { uuid4, uuidv4 } from "@/common/uuid";
import { feedbackTypeId } from "@/common/config";
import { mapState } from "vuex";

export default {
  name: "SendFeedBack",
  components: { Screenshot },
  data() {
    return {
      showScreenshot: false,
      show_: false,
      form: {
        image: null,
      },
    };
  },

  watch: {
    show_(val) {
      // if (!val) {
      //   this.onReset();
      // }
    },
  },

  methods: {
    show() {
      this.$refs["modal"].show();
      // console.log(this.user);
    },

    onClose() {
      this.form = {
        image: null,
      };
      this.showScreenshot = false;
      this.show_ = false;
    },
    makeScreenshot() {
      this.show_ = false;
      this.showScreenshot = true;

      this.$bvToast.toast(this.$t("FEEDBACK.SELECT_RECTANGLE"), {
        title: this.$t("COMMON.INFO"),
        variant: "info",
        autoHideDelay: 5000,
      });
    },

    onScreenshot(blob) {
      this.showScreenshot = false;
      this.show_ = true;
      this.form["image"] = blob;
    },

    async onSubmit() {
      try {
        const blob = this.form.image;
        let base64data = null;
        if (blob) {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          base64data = await (() => {
            return new Promise((resolve) => {
              reader.onloadend = function () {
                const base64data = reader.result;
                resolve(base64data);
              };
            });
          })();
        }

        await ApiService.post("/platform/api/feedback/add", {
          feedbackTypeId: feedbackTypeId,
          fields: {
            text: this.form.text,
            image: base64data,
            name: this.user.fields.name,
            email: this.user.signInIdentifier.value,
          },
        });
        this.$bvToast.toast(this.$t("FEEDBACK.FEEDBACK_SENT"), {
          title: this.$t("COMMON.SUCCESS"),
          variant: "success",
          autoHideDelay: 5000,
        });

        this.onClose();
      } catch (e) {
        const response = e;
        this.$bvToast.toast(
          response && response.message
            ? response.message
            : this.$t("COMMON.UNKNOWN_ERROR"),
          {
            title: this.$t("COMMON.ERROR"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
      }
    },
  },

  computed: {
    url() {
      if (this.form["image"]) {
        return URL.createObjectURL(this.form["image"]);
      }
      return null;
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>

<style scoped>
.screenshot-image {
  object-fit: scale-down;
  width: 100%;
  height: 200px;
  border: 1px solid #f2f2f2;
}

.screenshot-image--btn-close {
  position: absolute;
  right: 25px;
  top: 25px;
  transform: scale(2.5);
}
</style>
