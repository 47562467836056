<template>
  <ul class="menu-nav">
    <template v-for="item in items">
      <template v-if="item.submenu == null || item.submenu.length === 0">
        <router-link
          :to="'/' + item.page"
          :key="item.key"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text">
                {{ $t("MENU." + item.code.toUpperCase()) }}
              </span>
            </a>
          </li>
        </router-link>
      </template>
      <template v-else>
        <li
          :key="item.key"
          aria-haspopup="true"
          data-menu-toggle="click"
          class="menu-item menu-item-submenu menu-item-open-dropdown"
          v-bind:class="{ 'menu-item-active': hasActiveChildren(item.page) }"
        >
          <a href="#" class="menu-link menu-toggle">
            <span class="menu-text">
              {{ $t("MENU." + item.code.toUpperCase()) }}
            </span>
          </a>

          <div class="menu-submenu menu-submenu-classic">
            <ul class="menu-subnav">
              <router-link
                v-for="menuItem in item.submenu"
                :key="menuItem.key"
                :to="'/' + menuItem.page"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i :class="'menu-icon ' + menuItem.icon"></i>
                    <span class="menu-text">
                      {{ $t("MENU." + menuItem.code.toUpperCase()) }}
                    </span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
      </template>
    </template>
  </ul>
</template>

<script>
import menuConfig from "@/core/config/menu.config.json";
import Application from "@/application";
import { mapState } from "vuex";

export default {
  name: "KTMenu",
  data() {
    return {
      items: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created: function () {
    this.checkMenu();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    checkMenu: function () {
      let that = this;
      let allMenuItems = menuConfig.header.items;
      var res = setInterval(function () {
        if (that.user.grantsSet != undefined) {
          that.items = Application.userHasGrantForMenu(that.user, allMenuItems);
          clearInterval(res);
        }
      }, 100);
    },
  },
};
</script>
