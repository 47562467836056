<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          2024 &nbsp;&copy;&nbsp;
        </span>

        <a
          href="https://natlogger.com"
          target="_blank"
          class="text-dark-75 text-hover-primary"
        >
          Natlogger
        </a>
      </div>
      <div class="nav nav-dark">
        <b-link @click="onShowFeedBack" class="nav-link pr-3 pr-0">
          {{ $t("FOOTER.FEEDBACK") }}
        </b-link>

        <!-- <b-link @click="goToPaymentRules" class="nav-link pr-3 pr-0">
          {{ $t("PAYMENT_INFO.ABOUT_PAYMENT_RULES") }}
        </b-link> -->

        <!-- <b-link
          :href="'https://natlogger.com/pricing?l=' + lang"
          class="nav-link pr-3 pr-0"
        >
          {{ $t("FOOTER.PRICING") }}
        </b-link> -->

        <b-link
          :href="'https://natlogger.com/blog?l=' + lang"
          class="nav-link pr-3 pr-0"
        >
          {{ $t("FOOTER.BLOG") }}
        </b-link>

        <b-link
          :href="'https://natlogger.com/contacts?l=' + lang"
          class="nav-link pr-3 pr-0"
        >
          {{ $t("FOOTER.CONTACT") }}
        </b-link>

        <img
          src="/media/logos/fond_logo.png"
          style="height: 32px"
          class="ml-8 pr-3"
        />
      </div>
    </div>

    <SendFeedBack ref="sendFeedBack" />
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";
import SendFeedBack from "@/view/layout/extras/SendFeedBack";
import i18n from "@/core/plugins/vue-i18n";

export default {
  name: "KTFooter",
  components: { SendFeedBack },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
    lang() {
      return i18n.locale;
    },
  },

  methods: {
    onShowFeedBack() {
      this.$refs["sendFeedBack"].show();
    },
    goToPaymentRules() {
      this.$router.push({ name: "payment-rules" });
    },
    track() {
      this.$ga.page("/");
    },
  },
};
</script>
